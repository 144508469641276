import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import About from "../templates/about";

export const query = graphql`
  query AboutQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    about: sanityAbout(_id: { eq: "about" }) {
      _rawBodyBig
      _rawBodySmall
      images {
        ...SanityImage
        alt
      }
    }
    team: allSanityAuthor(sort: { fields: letter }) {
      nodes {
        _id
        name
        letter
        subtitle
        image {
          ...SanityImage
          alt
        }
        _rawBio
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <About data={data} />;
};

export default AboutPage;
